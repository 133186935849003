export const schemaLight = {
    size: {
        xl: '1024',
        lg: '966',
        md: '560',
        sm: '360',
        xxl: '1240'
    },
    mainColor: 'black',
    colors: {
        main: '#7e2625',
        second: '#f24405',
        technologyBorder: '#f24405',
        light: '#f29863',
        button: '#e97b5c',
        shadow: '#e9dad1',
        body: '#d9bead',
        footer: '#7e2625',
    },
    lato: '"Lato", sans-serif',
    jost: '"Jost", sans-serif',
    dark: false
}
export const schemaDark = {
    size: {
        xl: '1024',
        lg: '966',
        md: '560',
        sm: '360',
        xxl: '1240'
    },
    mainColor: 'white',
    colors: {
        main: 'white',
        second: 'lightgray',
        light: 'lightgray',
        button: 'lightgray',
        shadow: 'lightgray',
        body: '#000',
        footer: '#000',
        technologyBorder: 'rgba(255,255,255,0.3)',
    },
    lato: '"Lato", sans-serif',
    jost: '"Jost", sans-serif',
    dark: true
}